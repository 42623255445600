<template>
    <div class="content activeVip choiceCar">
        <div class="activeVip-tit">
            VIP > {{ topic }}科目 >
            <font color="#ff470f">VIP</font>
            <span style="margin-left:50px;">
                <el-radio-group v-model="topicId">
                    <el-radio-button :label="1">科目一</el-radio-button>
                    <el-radio-button :label="4">科目四</el-radio-button>
                </el-radio-group>
            </span>
        </div>
        <div class="choiceCar-con flex flex-jc">
            <div class="choiceCar-tab fl">
                <div class="choiceCar-tab-item vip-tab">
                    <p
                        @click="tabChoose(item.Id)"
                        v-for="item in topicId === 1 ? category1 : category4"
                        :key="item.Id"
                    >
                        <span
                            class="tab-item-img01"
                            :style="{width:item.Id < 0 ? '50px':''}"
                        >
                            <img :src="item.ImgUrl || require('../../images/seat.png')" />
                        </span>
                        <samp :class="active=== item.Id ?'tab-txt-lin':''">{{ item.Name }}</samp>
                    </p>
                </div>
            </div>
            <div class="choiceCar-subject fr">
                <ul class="vip-subject-con flex">
                    <li
                        v-for="(item,index) in children"
                        :key="item.Id"
                        @click="childClick(item)"
                    >
                        <span>
                            <samp>{{ index + 1 }}</samp>
                        </span>
                        <samp>{{ item.Name }}</samp>
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="popup"
            v-if="false"
        >
            <div class="active-popup animated fadeIn">
                <div
                    class="activeVip-popup"
                    v-show="activeVip"
                >
                    <div class="vip-popup-img">
                        <img src="../../images/vip_bg01.png" />
                    </div>
                    <div class="vip-popup-con">
                        <p class="vip-pop-ctxt01">请登录</p>
                        <p class="vip-pop-ctxt02">登录激活后继续答题</p>
                    </div>
                    <div class="vip-popup-btn flex flex-jc">
                        <div
                            class="popup-btn01"
                            @click="closeActive()"
                        >取消</div>
                        <div
                            class="popup-btn02"
                            @click="onActiveBtn()"
                        >激活</div>
                    </div>
                </div>
                <div
                    class="activeVip-state"
                    v-show="activeVipBtn"
                >
                    <div class="state-popup-tit flex">
                        <p
                            :class="activeVipID=='1'?'state-pop-lin01':''"
                            @click="activeMode('1')"
                        >激活码激活</p>
                        <p
                            :class="activeVipID=='2'?'state-pop-lin02':''"
                            @click="activeMode('2')"
                        >套餐激活</p>
                    </div>
                    <div
                        class="state-popup-con"
                        v-if="activeVipID == '1'"
                    >
                        <div class="state-popup-ipt">
                            <input
                                type="text"
                                v-model="activeCode"
                                value
                                placeholder="请输入激活码"
                            />
                        </div>
                    </div>
                    <div
                        class="state-popup-combo"
                        v-if="activeVipID == '2'"
                    >
                        <div
                            v-for="(item,index) in packages"
                            :key="index"
                            @click="sleCombo(index)"
                            :class="comboImg==index?'combo-popup-lin':'combo-popup-con'"
                        >
                            <div class="combo-popup-tit flex flex-sb">
                                <p class="combo-ptit">
                                    <span>{{item.Title}}</span>
                                    <samp></samp>
                                </p>
                                <p class="combo-price">{{item.Money}}元/{{item.PCType===1?item.PCDay + '天':'永久'}}</p>
                            </div>
                            <div class="combo-popup-txt">{{item.Remark}}</div>
                            <div
                                class="combo-img"
                                v-show="comboImg==index"
                            >
                                <img src="../../images/vip_icon01.png" />
                            </div>
                        </div>
                    </div>
                    <div class="vip-popup-btn flex flex-jc">
                        <div
                            class="popup-btn01"
                            @click="closeActive()"
                        >取消</div>
                        <div
                            class="popup-btn02"
                            @click="activeVip2()"
                        >激活</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../styles/public.scss';
@import '../exam/choiceCar.scss';
@import 'active_vip.scss';
@import '../../styles/vipPopup.scss';
.activeVip-tit /deep/ .el-radio-group {
    .el-radio-button {
        .el-radio-button__inner:hover {
            color: #ff621a;
        }
        &.is-active {
            input.el-radio-button__orig-radio:checked
                + .el-radio-button__inner {
                &:hover {
                    color: #fff;
                }
                background-color: #ff621a;
                border-color: #ff621a;
                box-shadow: -1px 0 0 0 #ff621a;
            }
        }
    }
}
</style>
<script>
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            topicId: 1,
            children: [],
            category1: [],
            category4: [],
            active: '1',
            ifLog: 1,
            activeVipBtn: false,
            activeVipID: '1',
            activeVip: true,
            comboImg: false,
            showVip: false,
            activeType: 1, // 1:激活码,2:套餐
            packageId: 0, // 套餐id
            activeCode: '', // 激活码
            packages: []// 套餐列表
        };
    },
    watch: {
        topicId() {
            this.active = 0;
        }
    },
    created() {
        this.vipLog();
        var ciphertext = Cookies.get('currentUser');
        if (ciphertext) {
            var bytes = CryptoJS.AES.decrypt(ciphertext, 'qazxswedcvfr');
            var str = bytes.toString(CryptoJS.enc.Utf8);
            if (str) {
                var obj = JSON.parse(str);
                if (obj) {
                    if (obj.Type !== 2) {
                        this.showVip = true;
                        this.getPackage();
                    }
                }
            }
        }
        this.getCategory();
    },
    computed: {
        ...mapState(['car']),
        topic() {
            if (this.car && this.car.selectId) {
                const item = this.car.car.find(a => a.Id === this.car.selectId);
                return item ? item.Name : '';
            }
            return '';
        }
    },
    components: {
    },
    methods: {
        // 获取二级菜单
        getCategory() {
            if (this.car && this.car.selectId) {
                this.$api.getVIPProblemCategoryByCarId({ carId: this.car.selectId }).then(res => {
                    const lastArr = [{ Id: -1, Name: '我的错题', ImgUrl: require('../../images/icon03.png') }, { Id: -2, Name: '模拟考试', ImgUrl: require('../../images/icon04.png') }];
                    this.category1 = [...res.km1, ...lastArr];
                    this.category4 = [...res.km4, ...lastArr];
                    // if (res.km1.length > 0) {
                    //     const id = res.km1[0].Id;
                    //     this.active = id;
                    // }
                });
            } else {
                setTimeout(() => {
                    this.getCategory();
                }, 500);
            }
        },
        // 子类点击事件
        childClick(item) {
            if (item.IsContProblem) {
                this.$router.push({ path: '/examExercises', query: { id: item.Id, type: this.topicId } });
            } else {
                this.$router.push({ path: `/skillTest/${item.Id}`, query: { name: item.Name, type: this.topicId } });
            }
        },
        /**
         * 选择分类
         */
        tabChoose(id) {
            if (this.active === id) {
                return;
            }
            this.active = id;
            const list = this.topicId === 1 ? this.category1 : this.category4;
            const item = list.find(a => a.Id === id);
            if (item) {
                if (id === -2) {
                    this.$router.push({ path: '/modelTest', query: { type: this.topicId, carId: this.car.selectId } });
                } else if (id === -1) {
                    this.$router.push({ path: '/examExercises', query: { id, type: this.topicId } });
                } else if (item.HasChildProblem) {
                    this.$router.push({ path: '/examExercises', query: { id, type: this.topicId } });
                } else if (item.HasChildCategory) {
                    this.getSubCategory();
                } else {
                    this.$message({
                        type: 'info',
                        message: '该分类为空',
                        showClose: true
                    });
                }
            }
        },
        getSubCategory() {
            this.$api.getSubCategory({ categoryId: this.active, carId: this.car.selectId, subjecType: this.topicId }).then(res => {
                this.children = res.data;
            });
        },
        /*
        * 判断vip是否登录
        * */
        vipLog() {
            // this.showVip = true;
        },
        /*
          * 激活Vip
          * */
        onActiveBtn() {
            this.activeVipBtn = true;
            this.activeVip = false;
        },
        /*
        * 取消激活
        * */
        closeActive() {
            this.showVip = !this.showVip;
        },
        /*
        * 选择激活方式
        * */
        activeMode(id) {
            this.activeVipID = id;
            this.activeType = Number(id);
        },
        /*
        * 选择套餐
        * */
        sleCombo(index) {
            this.comboImg = index;
            this.packageId = this.packages[index].Id;
        },
        /**
         * 激活VIP
         * type:1:激活码,2:套餐
         */
        activeVip2() {
            switch (this.activeType) {
                case 1:
                    // console.log('aaa');
                    if (!this.activeCode) {
                        this.$message.error('请输入激活码');
                        return false;
                    }
                    this.$api.activeVipByCode(this.activeCode).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                    });
                    break;
                case 2:
                    // console.log('bbb');
                    if (this.packageId === 0) {
                        this.$message.error('请选择套餐');
                        return false;
                    }
                    this.$api.activeVipByPackage(this.packageId).then(res => {
                        // console.log('res:', res);
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                    });
                    break;
            }
        },
        getPackage() {
            if (this.packages.length === 0) {
                this.$api.getPackage().then(res => {
                    this.packages = res.data;
                });
            }
        }
    }
};
</script>
